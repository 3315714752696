(function () {
    'use strict';

    angular.module('informaApp')
        .service('ObjectPropertyReader', ObjectPropertyReader);

    function ObjectPropertyReader() {
        return {
            getPropertyOrDefault: (options, getValue, defaultValue) => {
                if (!options) {
                    return defaultValue;
                }

                const value = getValue(options);

                return value === undefined ? defaultValue : value;
            }
        }
    }
})();